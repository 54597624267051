
import React from "react"


const Bio = () => {
    return (
        <div>
            <p>
       
            </p>
        </div>
    )
};

export default Bio
