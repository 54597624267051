import React from "react"
import { Link } from "gatsby"


const Header = () => {
    return (

    <div className="nav">
      <Link to={`/`}>
        <img className="logo" src={'/logo-blog-2.svg'} alt="Tyve-blog" />
      </Link>
      <a className="return-link" href="https://tyve.org">back to Tyve.org</a>

     </div>
    )
};

export default Header
