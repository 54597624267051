import React from "react"
import Header from "./header"
import CookieConsent from "react-cookie-consent";


class Layout extends React.Component {
    render() {
        const {location, title, children} = this.props;
        const rootPath = `${__PATH_PREFIX__}/`;
        return (
            <div>
                <div>
                    <Header/>
                </div>
                <div className="container">
                    <main>{children}</main>
                </div>
                <footer>
                    © {new Date().getFullYear()} Tyve
                    <a className="" href="https://www.iubenda.com/privacy-policy/97836779">Privacy Policy</a>
                    <a className="" href="https://www.tyve.org/cookie-policy.html">Cookie Policy</a>
                </footer>
                <CookieConsent
                    location="bottom"
                    buttonText="Got it!"
                    cookieName="cookieconsent_status"
                    expires={150}
                >
                    This website uses cookies to ensure you get the best experience on our website. <a
                    href="https://www.tyve.org/cookie-policy.html" style={{color: '#8BC1FF'}}>Learn more</a>
                </CookieConsent>
            </div>
        )
    }
}

export default Layout
